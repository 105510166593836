export enum SettingActionType {
    SETTING = "SETTING_DATA",
    SETTING_RESET="SETTING_RESET"
}

export enum BillActionType {
    BILL = "BILL_DATA",
    BILL_REFRESH = "BILL_REFRESH_DATA",
    BILL_UPDATE = "BILL_UPDATE",
    BILL_RESET = "BILL_RESET",
    BILL_ERROR_UPDATE = "BILL_ERROR_UPDATE",
}

export enum ErrorActionType {
    SETTING_ERROR = "SETTING_ERROR",
    BILL_ERROR = "BILL_ERROR"
}

export enum LoadingActionType {
    SETTING_LOADING="SETTING_LOADING",
    BILL_LOADING= "BILL_LOADING",
    BILL_FORCE_LOADING= "BILL_FORCE_LOADING",
    BILL_FETCHING= "BILL_FETCHING",
}

export enum TippingActionType {
    UPDATE_TIP="UPDATE_TIP",
    NO_TIP="NO_TIP",
    RESET="RESET",
    UPDATE_STAFF="UPDATE_STAFF"
}

export enum PopupActionType {
    BILL_CHANGE_POPUP="BILL_CHANGE_POPUP"
}

export enum SplitActionType {
    SPLIT_RESET_PARTIAL = "SPLIT_RESET_PARTIAL",
    SPLIT_RESET_FULL = "SPLIT_RESET_FULL",
    CONFIRM_EQUAL_SPLIT = "CONFIRM_EQUAL_SPLIT",
    CONFIRM_CUSTOM_SPLIT = "CONFIRM_CUSTOM_SPLIT",
    UPDATE_PAST_SPLIT = "UPDATE_PAST_SPLIT",
    UPDATE_PAYER_NAME = "UPDATE_PAYER_NAME",
    UPDATE_AMOUNT_PAYABLE = "UPDATE_AMOUNT_PAYABLE",
    UPDATE_SPLIT_TYPE = "UPDATE_SPLIT_TYPE",
    UPDATE_SPLIT_TYPE_LOCAL = "UPDATE_SPLIT_TYPE_LOCAL",
    UPDATE_PAY_BALANCE = "UPDATE_PAY_BALANCE",
    BUZZ_PAYER_INPUT = "BUZZ_PAYER_INPUT",
    UPDATE_POLLING = "UPDATE_POLLING"
}

export enum AmountReducerType {
    UPDATE_FINAL_AMOUNT = "UPDATE_FINAL_AMOUNT",
}

