import axios from 'axios';
import {BE_HOST} from '../data/constants'

const axiosInstance = axios.create({
    baseURL: BE_HOST,
    withCredentials:true,
    timeout:30000,
    headers:{
        "Accept-Language": localStorage.getItem("i18nextLng")
    }
});


export default axiosInstance;