import React, { useEffect } from 'react'
import { CommonErrorProps } from '../types';
import { useTranslation } from 'react-i18next';
import { info } from '../../../utils/dd-setup';
import { State } from '@store/index';
import { useSelector } from 'react-redux';
import { EVENTS } from '@constant';


const CommonError = ({img,errorTitle,errorDesc}:CommonErrorProps) => {

  const {setting:{settingData}} = useSelector((state:State)=> state)
  
  const {t} = useTranslation();

  useEffect(()=>{
    if(errorTitle === t('page_not_found')){
      info(`${EVENTS.PAGE_NOT_FOUND}|merchantKey=${settingData?.merchantKey}|tableNo=${settingData?.tableNo}`)
    }
  },[])

  return (
    <div className="use-bg use-max">
        <div className="misc-content">
        <div className="wrap-img">
            <img src={img} alt={errorTitle} />
        </div>
        {errorTitle}
        <span>{errorDesc}</span>
        </div>
    </div>
  )
}

export default CommonError
