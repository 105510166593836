import {Action} from '../actions/index'
import {PopupActionType} from '../action-types/index'

const initalState = {billChngPop:false,billChngPopData:{prevAmt:0,action:'',billChangePopup:'', prevBillAmt:''}};

const PopupReducer = (state:any = initalState, action:Action) =>{
    switch(action.type){
        case PopupActionType.BILL_CHANGE_POPUP:
            return {...state, billChngPop:action.payload.state, billChngPopData: {prevAmt:action.payload.prevAmt,prevBillAmt:action.payload.prevBillAmt,billChangePopup:action.payload.billChangePopup, action: action.payload.action}};
        default:
            return state
    }
}

export default PopupReducer