import React from 'react'
import { ReactComponent as IconRefresh } from '../../../assets/img/icon/refresh-lite.svg';
import { useTranslation } from 'react-i18next';

const InternetConnection = () => {
  const {t} = useTranslation()
  const reload = () =>{
    window.location.reload()
  }
  return (
    <div className="use-bg use-max">
        <div className="misc-content">
          <div className="wrap-img">
            <img src={localStorage.getItem('connection') as string} alt="Lost Connection" />
          </div>
          {t('lost_connection')}
          <span>{t('no_internet')}</span>
          <div>
            <button type="button" onClick={reload}><IconRefresh />{t('try_again')}</button>
          </div>
        </div>
    </div>
  )
}

export default InternetConnection