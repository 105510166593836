import {Action} from '../actions/index'
import {AmountReducerType} from '../action-types/index'
import { AmountReducerStateType } from '@store/state-types';

const initalState = {finalAmount: ''};

const AmountReducer = (state:AmountReducerStateType = initalState, action:Action) =>{
    switch(action.type){
        case AmountReducerType.UPDATE_FINAL_AMOUNT:
           return {...state, finalAmount: action.payload.finalAmount}
        default:
            return state
    }
}

export default AmountReducer;