import {Action} from '../actions/index'
import {TippingActionType} from '../action-types/index'
import { TippingReducerStateType } from '../state-types';
import { CUSTOM_TIP, PERCENTAGE_TIP } from '../../data/constants';

const initalState = {tipAmount:'', tipFinalAmt: '', tipPercentage:null, tipType:'', staffName:'', finalAmount: null, customTipValid:false, selectedTip:'', isButtonClick: false};

const TipReducer = (state:TippingReducerStateType = initalState, action:Action):TippingReducerStateType =>{
    switch(action.type){
        case TippingActionType.UPDATE_TIP:
            return {...state,
                tipAmount: action.payload.tipAmount,
                tipFinalAmt: action.payload.tipFinalAmt,
                staffName:action.payload.staffName ? action.payload.staffName : state.staffName,
                tipPercentage:action.payload.tipType === PERCENTAGE_TIP ? action.payload.tipPercentage : null,
                tipType:action.payload.tipType,
                customTipValid:action.payload.tipType === CUSTOM_TIP ? action.payload.tipValid : false,
                ...(action.payload.selectedTip) && {selectedTip: action.payload.selectedTip},
                ...(typeof action.payload.isButtonClick === 'boolean') && {isButtonClick: action.payload.isButtonClick}
            };
        case TippingActionType.NO_TIP:
            return {
                ...state, 
                tipAmount:'',
                tipFinalAmt:'',
                tipType:action.payload.tipType,
                staffName:'',
                tipPercentage:null,
                customTipValid:false, 
                isButtonClick: action.payload.isButtonClick,
                ...(action.payload.selectedTip) && {selectedTip: action.payload.selectedTip},
            };
        case TippingActionType.RESET:
            return {...state, tipAmount:'',tipFinalAmt:'', tipType:'', staffName:'', tipPercentage:null, customTipValid:false, selectedTip:'', isButtonClick: false};
        case TippingActionType.UPDATE_STAFF:
            return {...state, staffName: action.payload.staffName};
        default:
            return state
    }
}

export default TipReducer;