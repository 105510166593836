import {Action} from '../actions/index'
import {SettingActionType} from '../action-types/index'
import { SettingReducerStateType } from '../state-types';

const initalState = {settingData:null};

const SettingReducer = (state:SettingReducerStateType = initalState, action:Action):SettingReducerStateType =>{
    switch(action.type){
        case SettingActionType.SETTING:
            return {...state, settingData:action.payload};
        case SettingActionType.SETTING_RESET:
            return {...state, settingData:null};
        default:
            return state
    }
}

export default SettingReducer