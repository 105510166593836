import {combineReducers} from 'redux'
import SettingReducer from './setting-reducer'
import BillReducer from './bill-reducer';
import LoadingReducer from './loading-reducer';
import ErrorReducer from './error-reducer';
import TipReducer from './tip-reducer';
import PopupReducer from './popup-reducer';
import SplitReducer from './split-reducer';
import AmountReducer from './amount-reducer';

const reducers = combineReducers({
    setting: SettingReducer,
    bill: BillReducer,
    gLoading: LoadingReducer,
    gError: ErrorReducer,
    tip: TipReducer,
    popUp: PopupReducer,
    split: SplitReducer,
    amount: AmountReducer
})

export default reducers;

export type State = ReturnType<typeof reducers>