import {Action} from '../actions/index'
import {SplitActionType} from '../action-types/index'
import { SplitReducerStateType } from '../state-types';
import { SPLIT_TYPE } from '../../data/constants';

const initalState = {noOfPeopleOnTbl:2, noOfPeoplePayingFor: 1, amountPayable: 0, amountPerPax: 0, splitType: '', payerName: '', buzzPayerInput: false, confirm: false, payBalance:false, splitTypeLocal:'', splitPolling: true};

const SplitReducer = (state:SplitReducerStateType = initalState, action:Action):SplitReducerStateType =>{

    const { payload } = action;
    
    switch(action.type) {

        case SplitActionType.CONFIRM_EQUAL_SPLIT:
            const {noOfPeopleOnTbl, noOfPeoplePayingFor ,amountPayable, amountPerPax } = action.payload
            return {...state, splitType: SPLIT_TYPE.EQUAL, noOfPeopleOnTbl, noOfPeoplePayingFor, amountPayable, amountPerPax, confirm:true, payBalance:false };

        case SplitActionType.CONFIRM_CUSTOM_SPLIT:
            return {...state, splitType: SPLIT_TYPE.CUSTOM, amountPayable: payload.amountPayable, confirm:true, payBalance:false };

        case SplitActionType.UPDATE_PAST_SPLIT:
            return {...state,
                    ...(payload.splitType) === SPLIT_TYPE.EQUAL && {amountPayable: payload.amountPayable, payBalance: payload.payBalance},
                    ...(payload.splitType) === SPLIT_TYPE.CUSTOM && {payBalance: true},
                    confirm: false,
                    splitType:  payload.splitType
                };

        case SplitActionType.SPLIT_RESET_PARTIAL:
            return {...state, noOfPeopleOnTbl:2, noOfPeoplePayingFor: 1, amountPerPax: 0, splitType: '', buzzPayerInput: false, confirm: false };

        case SplitActionType.SPLIT_RESET_FULL:
            return {...state, noOfPeopleOnTbl:2, noOfPeoplePayingFor: 1, amountPayable: 0, amountPerPax: 0, splitType: '', buzzPayerInput: false, confirm: false, payBalance:false, splitTypeLocal:'' };

        case SplitActionType.UPDATE_PAYER_NAME:
            return {...state, payerName: action.payload.name};

        case SplitActionType.UPDATE_AMOUNT_PAYABLE:
            return {...state, amountPayable: action.payload.amount, payBalance: false};

        case SplitActionType.UPDATE_SPLIT_TYPE:
            return {...state, splitType: action.payload.splitType};

        case SplitActionType.UPDATE_SPLIT_TYPE_LOCAL:
            return {...state, splitTypeLocal: action.payload.splitType};

        case SplitActionType.UPDATE_PAY_BALANCE:
            return {...state, payBalance: action.payload.payBalance};

        case SplitActionType.BUZZ_PAYER_INPUT:
            return {...state, buzzPayerInput: action.payload.buzzPayerInput};

        case SplitActionType.UPDATE_POLLING:
            return {...state, splitPolling: action.payload.splitPolling};

        default:
            return state

    }
}

export default SplitReducer;