import { applyMiddleware, legacy_createStore as createStore} from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'setting',
    storage: storage,
    whitelist: ['setting','bill'], // which reducer want to store
};

const pReducer = persistReducer(persistConfig, reducers);

const store = createStore(pReducer,{},applyMiddleware(thunk))

const persistor = persistStore(store);

export { persistor, store };