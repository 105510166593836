import axiosInstance from '../utils/axios'

export default class HttpService {

    get(url:string,signal?:AbortSignal,params?:any){
        return axiosInstance.get(url,{
            signal,
            params
        })
    }

    post(url:string, body:any, params = {} ,signal?:AbortSignal){
        return axiosInstance.post(url,body,{
            signal,
            params
        })
    }

    delete(url:string,id:string,signal?:AbortSignal){
        if(id) url = `${url}/${id}`;
        return axiosInstance.post(url,{
            signal
        })
    }
    
}