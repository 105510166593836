import {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom'
import Loading from '../pages/loading/loading'
import CommonError from '@pages/error/components/common-error';
import MiscItem from '../assets/img/misc/item.svg';
import { useTranslation } from 'react-i18next';

const ScanPage = lazy(()=> import('../pages/scan/scan'))
const ScanPageV2 = lazy(()=> import('../pages/scan-v2/scan-v2'))
const LandingPage = lazy(()=> import('../pages/landing-page/landing-page'))
const ViewBill = lazy(()=> import('../pages/view-bill/view-bill'))
const PaymentConfirm = lazy(()=> import('../pages/payment-confirm/payment-confirm'))
const Menu = lazy(()=> import('../pages/menu/menu'))

const RouteManager = () => {
    const {t} = useTranslation();
    return (
        <Suspense fallback={<Loading heading={t('page_loading')} desc={t('load_desc')}/>}>
            <Routes>
                <Route path = "/" element={<LandingPage/>}/>
                <Route path = "/scan/:token" element={<ScanPage/>}/>
                <Route path = "/merchant/:merchantId/table/:tableNo/reference_id/:referenceId" element={<ScanPageV2/>}/>
                <Route path = "/merchant/:merchantId/table/:tableNo" element={<ScanPageV2/>}/>
                <Route path = "/merchant/:merchantId/reference_id/:referenceId" element={<ScanPageV2/>}/>
                <Route path = "/bill" element={<ViewBill/>}/>
                <Route path = "/confirm" element={<PaymentConfirm/>}/>
                <Route path = "/menu" element={<Menu/>}/>
                <Route path = "*" element={<CommonError img={MiscItem} errorTitle={t('page_not_found')} errorDesc={t('rescan_qr')}/>}/>
            </Routes>
        </Suspense>
    );
}

export default RouteManager;
