import { BillActionType, ErrorActionType, LoadingActionType, PopupActionType, SettingActionType, SplitActionType } from "../action-types"
import { Dispatch } from "redux"
import { Action } from "../actions"
import landingPageService from "../../services/landing-page-service"
import viewBillService from "../../services/view-bill-service"
import { BILL_CHANGE_POPUP, GET_BILL_MAX_TIME_LIMIT_DIFF, MAX_REFRESH_DISABLE_TIME, PAGES, REFRESH_ACTION, SPLIT_THRESHOLD, SPLIT_TYPE } from "../../data/constants"
import { toast } from "react-toastify";
import { ReactComponent as IconCheck } from '../../assets/img/icon/check.svg';
import ToastMsg from "../../components/ui/toast-msg"
import ToastIcon from "../../components/ui/toast-icon"
import { isSplitInitiated } from "@helpers"

export const settingData = () =>{
    return (dispatch:Dispatch<Action>) => {
        dispatch({
            type:LoadingActionType.SETTING_LOADING,
            payload:true
        })
        landingPageService.getData().then(res=>{
            if(!res.error){
                dispatch({
                    type:SettingActionType.SETTING,
                    payload:res.data
                })
                dispatch({
                    type:LoadingActionType.SETTING_LOADING,
                    payload:false
                })
            }else{
                dispatch({
                    type:ErrorActionType.SETTING_ERROR,
                    payload:res.error
                })
                dispatch({
                    type:LoadingActionType.SETTING_LOADING,
                    payload:false
                })
            }
        })
    }
}

export const setSettingLoad = (loading:boolean) =>{
    return (dispatch:Dispatch<Action>) => {
        dispatch({
            type:LoadingActionType.SETTING_LOADING,
            payload:loading
        })
    }
}

export const getBill = (isBrowserRefresh:boolean,page:string) => {
    return (dispatch:Dispatch<Action>,getState:any) => {
        const currentState = getState();
        if(!currentState.gLoading.billFetching && !currentState.gLoading.billForceLoading){
            if(!isBrowserRefresh){
                handleHardRefreshBill(dispatch, currentState, page);
            }else{
                handleBrowserRefreshBill(dispatch, currentState, page);
            }
        }
    }
}

const handleHardRefreshBill = async (dispatch:Dispatch<Action>, currentState:any, page:string) => {

    const currentTime = Date.now()/1000;
    if((currentTime - currentState.bill.lastRefreshedAt) > (MAX_REFRESH_DISABLE_TIME/1000)){
        dispatch({
            type:LoadingActionType.BILL_FORCE_LOADING,
            payload:true
        })
        if(page !== PAGES.LANDING){
            dispatch({
                type:SplitActionType.UPDATE_POLLING,
                payload:{
                    splitPolling:false
                }
            })
        }

        // info(`Request at ${BE_API.BILL} mode - force merchant key - ${currentState.settingData?.merchantKey} session id - ${currentState.settingData?.xSpSessionID}`)
        const billRes = await viewBillService.getBill('force', page as string);
        if(!billRes.error) {
            const {data:latestBill} = billRes;
            const {bill: {billData: localBill}} = currentState; // local bill - current local state bill data
            if(latestBill.balanceAmount !== localBill.balanceAmount && page !== PAGES.LANDING){

                if(latestBill.billAmount !== localBill.billAmount){
                    if(currentState.split.confirm || isSplitInitiated(latestBill)){ 
                        dispatch({
                            type:SplitActionType.SPLIT_RESET_FULL
                        });
                    }
                }

                let billChangePopup = ''
                if((currentState.split.confirm || isSplitInitiated(latestBill)) && latestBill.billAmount === localBill.billAmount){
                    billChangePopup = BILL_CHANGE_POPUP.BALANCE_CHANGE;
                    updatePastSplit(dispatch, latestBill);
                } else if((currentState.split.confirm || isSplitInitiated(latestBill)) && latestBill.billAmount !== localBill.billAmount){
                    billChangePopup = BILL_CHANGE_POPUP.BILL_CHANGE;
                }else if(localBill.billAmount !== latestBill.billAmount) {
                    billChangePopup = BILL_CHANGE_POPUP.NO_SPLIT;
                }

                if(billChangePopup){
                    dispatch({
                        type:PopupActionType.BILL_CHANGE_POPUP,
                        payload:{
                            state:true,
                            action:REFRESH_ACTION,
                            prevAmt:localBill.balanceAmount,
                            billChangePopup,
                            prevBillAmt: localBill.billAmount,
                        }
                    })
                } else {
                    dispatch({
                        type:SplitActionType.UPDATE_POLLING,
                        payload:{
                            splitPolling:true
                        }
                    })                
                }
            } else if(page !== PAGES.LANDING){
                if(!currentState.split.confirm && (latestBill.paymentInfo?.splitBillInfo?.type && latestBill?.paymentInfo?.splitBillInfo?.billAmountSplitMadeOn === latestBill.billAmount)) {
                    updatePastSplit(dispatch, latestBill);
                }
                dispatch({
                    type:SplitActionType.UPDATE_POLLING,
                    payload:{
                    splitPolling:true
                    }
                })
            }

            dispatch({
                type:BillActionType.BILL_REFRESH,
                payload:latestBill
            })
            if(page !== PAGES.LANDING){
                toast.success(ToastMsg({heading:"Bill details updated successfully!"}), {
                    icon: ({theme, type}) =>  ToastIcon({Icon:IconCheck})
                });
            }
        }else{
            dispatch({
                type:ErrorActionType.BILL_ERROR,
                payload:billRes.error
            })
            dispatch({
                type:BillActionType.BILL_ERROR_UPDATE,
            })
        }
        dispatch({
            type:LoadingActionType.BILL_FORCE_LOADING,
            payload:false
        })
    }   
}

const handleBrowserRefreshBill = async (dispatch:Dispatch<Action>, currentState:any, page:string) => {
    const currentTime = Date.now()/1000;
    if((currentTime - currentState.bill.lastUpdatedAt) > GET_BILL_MAX_TIME_LIMIT_DIFF){
        dispatch({
            type:LoadingActionType.BILL_LOADING,
            payload:true
        })
        dispatch({
            type:LoadingActionType.BILL_FETCHING,
            payload:true
        })
        // info(`Request at ${BE_API.BILL} mode - init merchant key - ${settingData?.merchantKey} session id - ${settingData?.xSpSessionID}`)
        const billRes = await viewBillService.getBill('init',page as string);
            if(!billRes.error){
                dispatch({
                    type:BillActionType.BILL,
                    payload:billRes.data
                })
                dispatch({
                    type:ErrorActionType.BILL_ERROR,
                    payload:null
                })
            }else{
                dispatch({
                    type:ErrorActionType.BILL_ERROR,
                    payload:billRes.error
                })
                dispatch({
                    type:BillActionType.BILL_ERROR_UPDATE,
                })
            }
            dispatch({
                type:LoadingActionType.BILL_LOADING,
                payload:false
            })
            dispatch({
                type:LoadingActionType.BILL_FETCHING,
                payload:false
            })
    }else{
        dispatch({
            type:LoadingActionType.BILL_LOADING,
            payload:false
        })
    }
}

export const billDataUpdate = (billData:any) =>{
    return (dispatch:Dispatch<Action>) => {
            dispatch({
                type:BillActionType.BILL_UPDATE,
                payload:billData
            })     
        }
 }

 const updatePastSplit = (dispatch:any, bill:any) => {
    if(bill?.paymentInfo?.splitBillInfo?.type === SPLIT_TYPE.EQUAL) {
        const futureBalance = parseFloat(bill.balanceAmount) - bill.paymentInfo.splitBillInfo.amountPerPerson;
        dispatch({
        type:SplitActionType.UPDATE_PAST_SPLIT,
        payload:{
            amountPayable: bill.paymentInfo.splitBillInfo.amountPerPerson,
            splitType: SPLIT_TYPE.EQUAL,
            payBalance: futureBalance <= SPLIT_THRESHOLD && futureBalance !== 0 ? true : false
        }
        }) 
        
    } else if(bill?.paymentInfo?.splitBillInfo?.type === SPLIT_TYPE.CUSTOM){
        dispatch({
            type:SplitActionType.UPDATE_PAST_SPLIT,
            payload:{splitType: SPLIT_TYPE.CUSTOM}
        }) 
    }
 }