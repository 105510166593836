import { datadogLogs } from '@datadog/browser-logs'
import { DD } from '../data/constants';

export const initDataDogBrowserLog = () => {
    datadogLogs.init({
        clientToken: DD.CLIENT_TOKEN,
        site: DD.DD_SITE,
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service: DD.SERVICE_NAME
    });
    datadogLogs.logger.setLevel("info")
  };

export const info = (message: string,data?:any) => {
    if (process.env.REACT_APP_DD_ENABLE === 'true') {
      try {
          datadogLogs.logger.info(message,data)
      } catch (e) { }
    }
  };
  
export const warn = (message: string,data?:any) => {
    if (process.env.REACT_APP_DD_ENABLE === 'true') {
      try {
       datadogLogs.logger.warn(message,data)
      } catch (e) { }
    }
  };
  
export const ddError = (message: string,data?:any) => {
    if (process.env.REACT_APP_DD_ENABLE === 'true') {
      try {
        datadogLogs.logger.error(message,data)
      } catch (e) { }
    }
  };

  