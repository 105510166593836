import {Action} from '../actions/index'
import {LoadingActionType} from '../action-types/index'

const initalState = {settingLoading:true,billLoading:true,billForceLoading:false,billFetching:false};

const LoadingReducer = (state = initalState, action:Action) =>{
    switch(action.type){
        case LoadingActionType.SETTING_LOADING:
            return {...state, settingLoading:action.payload};
        case LoadingActionType.BILL_LOADING:
            return {...state, billLoading:action.payload};
        case LoadingActionType.BILL_FORCE_LOADING:
            return {...state, billForceLoading:action.payload};
        case LoadingActionType.BILL_FETCHING:
            return {...state, billFetching:action.payload};
        default:
            return state
    }
}

export default LoadingReducer