
import InternetConnection from './components/internet-connection';
import CommonError from './components/common-error';
import MiscQr from '../../assets/img/misc/qr.svg';
import MiscSystem from '../../assets/img/misc/system.svg';
import MiscTable from '../../assets/img/misc/table.svg';
import {ddError} from '../../utils/dd-setup'
import { useTranslation } from 'react-i18next';
const Error = ({error,resetErrorBoundary}:any) => {

    const {t} = useTranslation();
    if(!window.navigator.onLine){
        return (
            <InternetConnection/>
        )
    }

    if(error.response){
        if(error.response.status === 401 || error.response.data === "QR Code has been expired" || error.status === 401){
            return (
                <CommonError errorTitle={t('qr_expired')} errorDesc={t('rescan_qr')} img={MiscQr}/>
            )
        }else if(error.response.data === "QR Code is not found" || error.response.data === "merchantKey is not found" || error.response.data === "QR code does not exist or it has been deleted"){
            return (
                <CommonError errorTitle={t('table_not_found')} errorDesc={t('unable_to_accept_orders')} img={MiscTable}/>
            )
        }else{
            return (
                <CommonError errorTitle={t('system_error')} errorDesc={t('server_error_desc')} img={MiscSystem}/>
            )
        }
    }else if(error.request){
        return (
            <CommonError errorTitle={t('system_error')} errorDesc={t('server_error_desc')} img={MiscSystem}/>
        )
    }else{  
        if(error.status === 401 || error.message === 'Setting Not Found'){
            ddError(`${error.message}`, error)
            return (
                <CommonError errorTitle={t('qr_expired')} errorDesc={t('rescan_qr')} img={MiscQr}/>
            )
        }else{
            ddError(`Something Broken - ${error.message}`, error)
            return (
                <CommonError errorTitle='Something Broken!' errorDesc={''} img={MiscSystem}/>
            )
        }
    }

}

export default Error;
