import {Action} from '../actions/index'
import {BillActionType} from '../action-types/index'

const initalState = {lastUpdatedAt:null,lastRefreshedAt:null,billData:null};

const BillReducer = (state:any = initalState, action:Action) =>{
    switch(action.type){
        case BillActionType.BILL:
            return {...state, billData:action.payload, lastUpdatedAt: Date.now()/1000};
        case BillActionType.BILL_REFRESH:
            return {...state, billData:action.payload, lastRefreshedAt: Date.now()/1000,lastUpdatedAt: Date.now()/1000};
        case BillActionType.BILL_RESET:
            return {...state, billData:null, lastUpdatedAt: null,lastRefreshedAt:null};
        case BillActionType.BILL_ERROR_UPDATE:
            return {...state, lastUpdatedAt:null,billData:null,lastRefreshedAt:null};
        case BillActionType.BILL_UPDATE:
            return {...state, billData:action.payload, lastUpdatedAt: Date.now()/1000};
        default:
            return state
    }
}

export default BillReducer