import React from 'react'
import LoadingComponent from '../../components/ui/loading';
import { LoadingPageProps } from './types';

const Loading = ({heading,desc}:LoadingPageProps) => {
  return (
    <div className="use-bg use-max">
        <div className="misc-content">
          <LoadingComponent />
          {heading}
          <span>{desc}</span>
        </div>
      </div>
  )
}

export default Loading;