import React from 'react'

const ToastIcon = ({Icon}:any) => {
  return (
    <>
        <Icon/>
    </>
  )
}

export default ToastIcon