import {Action} from '../actions/index'
import {ErrorActionType} from '../action-types/index'

const initalState = {settingError:null,billError:null};

const ErrorReducer = (state = initalState, action:Action) =>{
    switch(action.type){
        case ErrorActionType.SETTING_ERROR:
            return {...state, settingError:action.payload};
        case ErrorActionType.BILL_ERROR:
            return {...state, billError:action.payload};
        default:
            return state
    }
}

export default ErrorReducer